import * as React from "react"
import { useState, useEffect } from "react"
import { useLazyBuildExcelQuery, useGetPayloadQuery } from "services/api"
import {
	createColumnHelper,
	flexRender,
	getCoreRowModel,
	useReactTable,
	getFilteredRowModel,
	getPaginationRowModel,
	getSortedRowModel,
} from "@tanstack/react-table"
import classNames from "classnames"
import ExcelExport from "./ExcelExport"
import ExcelExportLocalTable from "./ExcelExportLocalTable"
import Select from "components/Select"
import axios from "axios"
import { Modal } from "react-responsive-modal"
import _ from "lodash"

const formattedFilter = (row, id, filterValue) => {
	try {
		let val = row.getValue(id)
		if (React.isValidElement(val) && val?.props?.children) {
			val = val.props.children
		}
		val = val?.toString().toLowerCase()
		if (val?.includes(filterValue)) {
			return true
		}
		return false
	} catch (e) {
		console.warn(e)
	}
}

const columnHelper = createColumnHelper()

function FullTable(props) {
	let columns = props.columns.map((column) => {
		if (column.id === "checkbox") {
			return {
				...column,
				cell: ({ row }) => (
					<input
						type="checkbox"
						checked={row.original.isChecked}
						onChange={() => props.onCheckboxChange(row.index)}
					/>
				),
			}
		}
		return columnHelper.accessor(column.id, {
			...column,
			filterFn: "formatted",
		})
	})

	const [columnResizeMode, setColumnResizeMode] = useState("onChange")
	const [sorting, setSorting] = useState([])
	const [buildExcel] = useLazyBuildExcelQuery()
	//? Modal
	const [open, setOpen] = useState(false)
	const onOpenModal = () => setOpen(true)
	const onCloseModal = () => setOpen(false)
	const [modalType, setModalType] = useState(false)
	const [reportType, setReportType] = useState(false)

	const [tableData, setTableData] = useState(props.data)
	const payload = useGetPayloadQuery()

	useEffect(() => {
		setTableData(props.data)
	}, [props.data])

	const table = useReactTable({
		data: tableData,
		columns,
		state: {
			sorting,
		},
		onSortingChange: setSorting,
		getCoreRowModel: getCoreRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		globalFilterFn: formattedFilter,
		getPaginationRowModel: getPaginationRowModel(),
		filterFns: {
			formatted: formattedFilter,
		},
		columnResizeMode,
		columnResizeDirection: "ltr",
		getSortedRowModel: getSortedRowModel(),
	})

	const generateExcel = async () => {
		props.setLoading(true)
		let checked = _.map(tableData, (r) => {
			return { id: r.subID, isChecked: r.isChecked }
		})
		let resp = await buildExcel({ selections: props.selections, report: reportType, checked })
		let data = resp.data.data.report
		//return
		ExcelExport({
			data,
			title: "Export",
			payload: props.payload,
			filtersString: resp?.data?.data?.filtersString,
		})
		props.setLoading(false)
	}

	const generatePDF = async (opts) => {
		props.setLoading(true)
		const token = localStorage.getItem("user")
		if (!token) {
			console.log("No Bearer.")
			return
		}
		let type = reportType
		let template
		if (opts?.type) type = opts.type
		if (opts?.template) template = opts.template
		axios({
			url: `${process.env.REACT_APP_SERVER}/generatePDF`, // Your API endpoint here
			method: "POST",
			headers: {
				Authorization: `Bearer ${token}`,
			},
			responseType: "blob", // Important: specifies the response type as a blob
			data: {
				...props.selections,
				report: type,
				template,
				checked: _.map(tableData, (r) => {
					return { id: r.subID, isChecked: r.isChecked }
				}),
			},
		})
			.then((response) => {
				//return
				const url = window.URL.createObjectURL(new Blob([response.data]))
				const link = document.createElement("a")
				link.href = url
				link.setAttribute("download", "output.pdf") // or any other extension
				document.body.appendChild(link)
				link.click()
				document.body.removeChild(link) // Clean up
				props.setLoading(false)
			})
			.catch((error) => {
				console.error("Download error:", error)
				props.setLoading(false)
			})
	}

	let reports = []
	if (props?.payload?.reports) {
		if (modalType === "PDF") reports.push({ label: "Master Report", value: -1 })
		let indivs = []
		_.forEach(props.payload.reports, (r) => {
			if (modalType === "Excel" && !r.excel) return
			if (r.status) {
				indivs.push({ label: r.name, value: r.id })
			}
		})
		indivs = _.sortBy(indivs, "label")
		reports = reports.concat(indivs)
	}
	return (
		<div className="p-2">
			<div className="flex justify-between pb-4 text-sm">
				<div className="flex">
					<div>{table.getFilteredRowModel().rows.length} Rows</div>
					<div
						onClick={() => {
							table.resetColumnFilters(true)
						}}
						className="smallBtn ml-3">
						<i className="fa-duotone fa-circle-xmark mr-2"></i>Clear Filters
					</div>
				</div>
				{props.admin ? (
					<div>
						<button
							className="smallBtn ml-2"
							onClick={() => {
								const rowModel = table.getFilteredRowModel().rows
								let rows = _.map(rowModel, (r) => r.original)
								ExcelExportLocalTable({
									data: rows,
									payload: payload?.data,
									title: "Export",
									unset: ["builders", "addedDate", "entries", "community", "comments", "edit"],
								})
							}}>
							Export to Excel
						</button>
					</div>
				) : (
					<div>
						{props?.viewing?.name === "subdivisions" && (
							<button
								className="smallBtn ml-2"
								onClick={() => {
									setModalType("PDF")
									setOpen(true)
								}}>
								Export PDF
							</button>
						)}
						{props?.viewing?.name === "subdivision" && (
							<button
								className="smallBtn ml-2"
								onClick={() => {
									generatePDF({ type: 14 })
								}}>
								Export Subdivision PDF Report
							</button>
						)}
						<button
							className="smallBtn ml-2"
							onClick={() => {
								setModalType("Excel")
								setOpen(true)
							}}>
							Export to Excel
						</button>
					</div>
				)}
			</div>
			<table className={classNames("fullTable", props.classNames)}>
				<thead>
					{table.getHeaderGroups().map((headerGroup) => (
						<tr key={headerGroup.id}>
							{headerGroup.headers.map((header) => {
								let size = header.getSize()
								if (header?.column?.columnDef?.width) size = header.column.columnDef.width
								return (
									<th
										{...{
											key: header.id,
											colSpan: header.colSpan,
											style: {
												width: size,
											},
										}}>
										{header.isPlaceholder ? null : (
											<div
												className={
													header.column.getCanSort() ? "cursor-pointer select-none" : ""
												}
												onClick={header.column.getToggleSortingHandler()}
												title={
													header.column.getCanSort()
														? header.column.getNextSortingOrder() === "asc"
															? "Sort ascending"
															: header.column.getNextSortingOrder() === "desc"
															? "Sort descending"
															: "Clear sort"
														: undefined
												}>
												{flexRender(header.column.columnDef.header, header.getContext())}
												{{
													asc: (
														<svg
															className="w-2 inline ml-2"
															xmlns="http://www.w3.org/2000/svg"
															viewBox="0 0 384 512">
															<path d="M169.4 41.4c12.5-12.5 32.8-12.5 45.3 0l160 160c9.2 9.2 11.9 22.9 6.9 34.9s-16.6 19.8-29.6 19.8H256V440c0 22.1-17.9 40-40 40H168c-22.1 0-40-17.9-40-40V256H32c-12.9 0-24.6-7.8-29.6-19.8s-2.2-25.7 6.9-34.9l160-160z" />
														</svg>
													),
													desc: (
														<svg
															className="w-2 inline ml-2"
															xmlns="http://www.w3.org/2000/svg"
															viewBox="0 0 384 512">
															<path d="M214.6 470.6c-12.5 12.5-32.8 12.5-45.3 0l-160-160c-9.2-9.2-11.9-22.9-6.9-34.9s16.6-19.8 29.6-19.8l96 0 0-184c0-22.1 17.9-40 40-40l48 0c22.1 0 40 17.9 40 40l0 184 96 0c12.9 0 24.6 7.8 29.6 19.8s2.2 25.7-6.9 34.9l-160 160z" />
														</svg>
													),
												}[header.column.getIsSorted()] ?? null}
												{header.column.getCanFilter() &&
												header?.column?.columnDef?.filterable !== false ? (
													<div>
														<Filter column={header.column} table={table} />
													</div>
												) : null}
												<div
													{...{
														onDoubleClick: () => header.column.resetSize(),
														onMouseDown: header.getResizeHandler(),
														onTouchStart: header.getResizeHandler(),
														className: `resizer ${table.options.columnResizeDirection} ${
															header.column.getIsResizing() ? "isResizing" : ""
														}`,
														style: {
															transform:
																columnResizeMode === "onEnd" &&
																header.column.getIsResizing()
																	? `translateX(${
																			1 *
																			(table.getState().columnSizingInfo
																				.deltaOffset ?? 0)
																	  }px)`
																	: "",
														},
													}}
												/>
											</div>
										)}
									</th>
								)
							})}
						</tr>
					))}
				</thead>
				<tbody>
					{table.getRowModel().rows.map((row) => (
						<tr key={row.id}>
							{row.getVisibleCells().map((cell) => {
								// Check if the cell's value is a React element
								const isReactElement = React.isValidElement(cell.getValue())

								// Directly return the React element if it is one,
								// otherwise, use flexRender for custom cell formatting
								return (
									<td key={cell.id}>
										{isReactElement
											? cell.getValue()
											: flexRender(cell.column.columnDef.cell, cell.getContext())}
									</td>
								)
							})}
						</tr>
					))}
				</tbody>
				<tfoot>
					{table.getFooterGroups().map((footerGroup) => (
						<tr key={footerGroup.id}>
							{footerGroup.headers.map((header) => (
								<th key={header.id}>
									{header.isPlaceholder
										? null
										: flexRender(header.column.columnDef.footer, header.getContext())}
								</th>
							))}
						</tr>
					))}
				</tfoot>
			</table>
			<div className="h-4" />
			<div className="flex justify-around fullTable__controls">
				<div className="flex-1 flex">
					<button
						className="fullTable__control"
						onClick={() => table.setPageIndex(0)}
						disabled={!table.getCanPreviousPage()}>
						{"<<"}
					</button>
					<button
						className="fullTable__control"
						onClick={() => table.previousPage()}
						disabled={!table.getCanPreviousPage()}>
						{"<"}
					</button>
				</div>
				<div className="flex-2 flex justify-center text-xs">
					<span className="flex items-center gap-1">
						<div>Page</div>
						<strong>
							{table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
						</strong>
					</span>
					<span className="flex items-center gap-1">
						| Go to page:
						<input
							type="number"
							defaultValue={table.getState().pagination.pageIndex + 1}
							onChange={(e) => {
								const page = e.target.value ? Number(e.target.value) - 1 : 0
								table.setPageIndex(page)
							}}
							className="border px-2 py-2 rounded w-16 mr-4"
						/>
					</span>
					<select
						className="border px-3 py-1 rounded border-neutral-200"
						value={table.getState().pagination.pageSize}
						onChange={(e) => {
							table.setPageSize(Number(e.target.value))
						}}>
						{[10, 20, 30, 40, 50].map((pageSize) => (
							<option key={pageSize} value={pageSize}>
								Show {pageSize}
							</option>
						))}
					</select>
				</div>
				<div className="flex-1 flex justify-end">
					<button
						className="fullTable__control"
						onClick={() => table.nextPage()}
						disabled={!table.getCanNextPage()}>
						{">"}
					</button>
					<button
						className="fullTable__control"
						onClick={() => table.setPageIndex(table.getPageCount() - 1)}
						disabled={!table.getCanNextPage()}>
						{">>"}
					</button>
				</div>
			</div>
			<Modal
				open={open}
				onClose={onCloseModal}
				center
				classNames={{
					modal: "small addHeight",
				}}>
				<h2 className="text-darkBlue font-bold">Export Report for {modalType}</h2>
				<div className="mt-8">
					<Select
						name="builders"
						value={reportType}
						className="smallText"
						onChange={(k, v) => {
							setReportType(v)
						}}
						options={reports}
					/>
				</div>
				<button
					className="btn mt-4"
					onClick={() => {
						if (modalType === "Excel") generateExcel()
						else if (modalType === "PDF") generatePDF()
					}}>
					Export
				</button>
			</Modal>
		</div>
	)
}

function Filter({ column, table }) {
	//const firstValue = table.getPreFilteredRowModel().flatRows[0]?.getValue(column.id)
	const columnFilterValue = column.getFilterValue()
	// Fall back to text
	return (
		<input
			type="text"
			value={columnFilterValue ?? ""}
			onChange={(e) => column.setFilterValue(e.target.value)}
			placeholder={`Filter`}
			className="fullTable__filter"
			onClick={(e) => {
				e.stopPropagation()
			}}
		/>
	)
}

export default FullTable
